import React from 'react';
import { graphql } from 'gatsby';

import PageLayout from '../components/PageLayout';
import SEO from '../components/seo';
import { Hero } from '../components/Hero';
import Section from '../components/Section';
import { Badges } from '../components/Badges';
import { Wrapper, Title, SubTitle } from '../styles/agb';

const TOC = ({ data: { Postbote } }) => {
  return (
    <PageLayout>
      <SEO title="Datenschutzerklärung" />
      <Hero image={Postbote} title="Datenschutzerklärung">
        <div className="font-display font-xl tracking-wide">
          Jetzt die DropFriends-App downloaden.
        </div>
        <Badges />
      </Hero>
      <Section>
        <Wrapper>
          <Title>Einleitung</Title>
          <p className="mb-4">
            Mit der folgenden Datenschutzerklärung möchten wir, DropFriends GmbH Sie darüber
            aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten“
            bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die
            Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen
            personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch
            insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
            Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend
            bezeichnet als "Onlineangebot“).
          </p>
          <p className="mb-4">
            Die verwendeten Begriffe sind nicht geschlechtsspezifisch. Stand: 16. März 2022
          </p>

          <Title>Verantwortlicher</Title>
          <p className="mb-4">
            DropFriends GmbH
            <br />
            Maybachstraße 22
            <br />
            50670 Köln
          </p>
          <p className="mb-4">
            <strong>Vertretungsberechtigte Personen:</strong> Martin Peters
            <br />
            <strong>E-Mail-Adresse:</strong> datenschutz@dropfriends.com
            <br />
            <strong>Impressum:</strong> www.dropfriends.com/impressum
          </p>

          <Title>Übersicht der Verarbeitungen</Title>
          <p className="mb-4">
            Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer
            Verarbeitung zusammen und verweist auf die betroffenen Personen.
          </p>

          <SubTitle>Arten der verarbeiteten Daten</SubTitle>
          <ul className="mb-4 list-disc pl-4">
            <li>Bestandsdaten (z.B. Namen, Adressen).</li>
            <li>
              Bewerberdaten (z.B. Angaben zur Person, Post- und Kontaktadressen, die zur Bewerbung
              gehörenden Unterlagen und die darin enthaltenen Informationen, wie z.B. Anschreiben,
              Lebenslauf, Zeugnisse sowie weitere im Hinblick auf eine konkrete Stelle oder
              freiwillig von Bewerbern mitgeteilte Informationen zu deren Person oder
              Qualifikation).
            </li>
            <li>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</li>
            <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
            <li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
            <li>Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
            <li>Standortdaten (Daten, die den Standort des Endgeräts eines Endnutzers angeben).</li>
            <li>Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).</li>
            <li>Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).</li>
          </ul>

          <SubTitle>Kategorien betroffener Personen</SubTitle>
          <ul className="mb-4 list-disc pl-4">
            <li>Beschäftigte (z.B. Angestellte, Bewerber, ehemalige Mitarbeiter).</li>
            <li>Bewerber.</li>
            <li>Geschäfts- und Vertragspartner.</li>
            <li>Interessenten.</li>
            <li>Kommunikationspartner.</li>
            <li>Kunden.</li>
            <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
          </ul>

          <SubTitle>Zwecke der Verarbeitung</SubTitle>
          <ul className="mb-4 list-disc pl-4">
            <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
            <li>Besuchsaktionsauswertung.</li>
            <li>
              Bewerbungsverfahren (Begründung und etwaige spätere Durchführung sowie mögliche
              spätere Beendigung des Beschäftigungsverhältnisses.).
            </li>
            <li>Büro- und Organisationsverfahren.</li>
            <li>Content Delivery Network (CDN).</li>
            <li>
              Cross-Device Tracking (geräteübergreifende Verarbeitung von Nutzerdaten für
              Marketingzwecke).
            </li>
            <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
            <li>Feedback (z.B. Sammeln von Feedback via Online-Formular).</li>
            <li>Interessenbasiertes und verhaltensbezogenes Marketing.</li>
            <li>Kontaktanfragen und Kommunikation.</li>
            <li>Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen).</li>
            <li>Profiling (Erstellen von Nutzerprofilen).</li>
            <li>Remarketing.</li>
            <li>
              Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).
            </li>
            <li>Sicherheitsmaßnahmen.</li>
            <li>
              Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies).
            </li>
            <li>Vertragliche Leistungen und Service.</li>
            <li>Verwaltung und Beantwortung von Anfragen.</li>
            <li>
              Zielgruppenbildung (Bestimmung von für Marketingzwecke relevanten Zielgruppen oder
              sonstige Ausgabe von Inhalten).
            </li>
          </ul>

          <SubTitle>Maßgebliche Rechtsgrundlagen</SubTitle>
          <p className="mb-4">
            Im Folgenden teilen wir die Rechtsgrundlagen der Datenschutzgrundverordnung (DSGVO), auf
            deren Basis wir die personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass
            zusätzlich zu den Regelungen der DSGVO die nationalen Datenschutzvorgaben in Ihrem bzw.
            unserem Wohn- und Sitzland gelten können.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> - Die betroffene
              Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden
              personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke
              gegeben.
            </li>
            <li>
              <strong>
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)
              </strong>{' '}
              - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die
              betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich,
              die auf Anfrage der betroffenen Person erfolgen.
            </li>
            <li>
              <strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong> - Die
              Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der
              Verantwortliche unterliegt.
            </li>
            <li>
              <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die
              Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder
              eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und
              Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten
              erfordern, überwiegen.
            </li>
            <li>
              <strong>
                Art. 9 Abs. 1 S. 1 lit. b DSGVO (Bewerbungsverfahren als vorvertragliches bzw.
                vertragliches Verhältnis) (Soweit im Rahmen des Bewerbungsverfahrens besondere
                Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B.
                Gesundheitsdaten, wie Schwerbehinderteneigenschaft oder ethnische Herkunft) bei
                Bewerbern angefragt werden, damit der Verantwortliche oder die betroffene Person die
                ihm bzw. ihr aus dem Arbeitsrecht und dem Recht der sozialen Sicherheit und des
                Sozialschutzes erwachsenden Rechte ausüben und seinen bzw. ihren diesbezüglichen
                Pflichten nachkommen kann, erfolgt deren Verarbeitung nach Art. 9 Abs. 2 lit. b.
                DSGVO, im Fall des Schutzes lebenswichtiger Interessen der Bewerber oder anderer
                Personen gem. Art. 9 Abs. 2 lit. c. DSGVO oder für Zwecke der Gesundheitsvorsorge
                oder der Arbeitsmedizin, für die Beurteilung der Arbeitsfähigkeit des Beschäftigten,
                für die medizinische Diagnostik, die Versorgung oder Behandlung im Gesundheits- oder
                Sozialbereich oder für die Verwaltung von Systemen und Diensten im Gesundheits- oder
                Sozialbereich gem. Art. 9 Abs. 2 lit. h. DSGVO. Im Fall einer auf freiwilliger
                Einwilligung beruhenden Mitteilung von besonderen Kategorien von Daten, erfolgt
                deren Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.) - .
              </strong>
            </li>
          </ul>
          <p className="mb-4">
            <strong>Nationale Datenschutzregelungen in Deutschland:</strong> Zusätzlich zu den
            Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum
            Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor
            Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz –
            BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht
            auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
            personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie
            automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Des
            Weiteren regelt es die Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§
            26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder Beendigung von
            Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können
            Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
          </p>
          <Title>Sicherheitsmaßnahmen</Title>
          <p className="mb-4">
            Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der
            Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der
            Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und
            des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete
            technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau
            zu gewährleisten.
          </p>
          <p className="mb-4">
            Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
            Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den
            Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung
            der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die
            eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
            Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz
            personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
            sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und
            durch datenschutzfreundliche Voreinstellungen.
          </p>
          <p className="mb-4">
            <strong>Kürzung der IP-Adresse:</strong> Sofern es uns möglich ist oder eine Speicherung
            der IP-Adresse nicht erforderlich ist, kürzen wir oder lassen Ihre IP-Adresse kürzen. Im
            Fall der Kürzung der IP-Adresse, auch als "IP-Masking" bezeichnet, wird das letzte
            Oktett, d.h., die letzten beiden Zahlen einer IP- Adresse, gelöscht (die IP-Adresse ist
            in diesem Kontext eine einem Internetanschluss durch den Online-Zugangs-Provider
            individuell zugeordnete Kennung). Mit der Kürzung der IP-Adresse soll die
            Identifizierung einer Person anhand ihrer IP-Adresse verhindert oder wesentlich
            erschwert werden.
          </p>
          <p className="mb-4">
            <strong>SSL-Verschlüsselung (https):</strong> Um Ihre via unser Online-Angebot
            übermittelten Daten zu schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen
            derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile Ihres
            Browsers.
          </p>
          <Title>Übermittlung und Offenbarung von personenbezogenen Daten</Title>
          <p className="mb-4">
            Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten
            an andere Stellen, Unternehmen, rechtlich selbstständige Organisationseinheiten oder
            Personen übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern
            dieser Daten können z.B. Zahlungsinstitute im Rahmen von Zahlungsvorgängen, mit
            IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in
            eine Webseite eingebunden werden, gehören. In solchen Fall beachten wir die gesetzlichen
            Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem
            Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
          </p>
          <Title>Datenverarbeitung in Drittländern</Title>
          <p className="mb-4">
            Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des
            Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
            Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an
            andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit
            den gesetzlichen Vorgaben.
          </p>
          <p className="mb-4">
            Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich
            erforderlicher Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern
            mit einem anerkannten Datenschutzniveau, zu denen die unter dem "Privacy-Shield"
            zertifizierten US-Verarbeiter gehören, oder auf Grundlage besonderer Garantien, wie z.B.
            vertraglicher Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission,
            des Vorliegens von Zertifizierungen oder verbindlicher interner Datenschutzvorschriften,
            verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der EU-Kommission:
            https://ec.europa.eu/info/law/law-topic/data-
            protection/international-dimension-data-protection_de ).
          </p>
          <Title>Einsatz von Cookies</Title>
          <p className="mb-4">
            Cookies sind Textdateien, die Daten von besuchten Websites oder Domains enthalten und
            von einem Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient in
            erster Linie dazu, die Informationen über einen Benutzer während oder nach seinem Besuch
            innerhalb eines Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B.
            die Spracheinstellungen auf einer Webseite, der Loginstatus, ein Warenkorb oder die
            Stelle, an der ein Video geschaut wurde, gehören. Zu dem Begriff der Cookies zählen wir
            ferner andere Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B., wenn
            Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert werden, auch als
            "Nutzer-IDs" bezeichnet)
          </p>
          <p className="mb-4 font-bold">
            Die folgenden Cookie-Typen und Funktionen werden unterschieden:
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre
              Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen
              und seinen Browser geschlossen hat.
            </li>
            <li>
              <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem
              Schließen des Browsers gespeichert. So kann beispielsweise der Login-Status
              gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine
              Website erneut besucht. Ebenso können die Interessen von Nutzern, die zur
              Reichweitenmessung oder zu Marketingzwecken verwendet werden, in einem solchen Cookie
              gespeichert werden.
            </li>
            <li>
              <strong>First-Party-Cookies:</strong> First-Party-Cookies werden von uns selbst
              gesetzt.
            </li>
            <li>
              <strong>Third-Party-Cookies (auch: Drittanbieter-Cookies):</strong>{' '}
              Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden (sog. Dritten)
              verwendet, um Benutzerinformationen zu verarbeiten.
            </li>
            <li>
              <strong>Notwendige (auch: essentielle oder unbedingt erforderliche) Cookies:</strong>{' '}
              Cookies können zum einen für den Betrieb einer Webseite unbedingt erforderlich sein
              (z.B. um Logins oder andere Nutzereingaben zu speichern oder aus Gründen der
              Sicherheit).
            </li>
            <li>
              <strong>Statistik-, Marketing- und Personalisierungs-Cookies:</strong> Ferner werden
              Cookies im Regelfall auch im Rahmen der Reichweitenmessung eingesetzt sowie dann, wenn
              die Interessen eines Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte,
              Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem Nutzerprofil gespeichert
              werden. Solche Profile dienen dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren
              potentiellen Interessen entsprechen. Dieses Verfahren wird auch als "Tracking", d.h.,
              Nachverfolgung der potentiellen Interessen der Nutzer bezeichnet. . Soweit wir Cookies
              oder "Tracking"- Technologien einsetzen, informieren wir Sie gesondert in unserer
              Datenschutzerklärung oder im Rahmen der Einholung einer Einwilligung.
            </li>
          </ul>
          <p className="mb-4">
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Auf welcher Rechtsgrundlage wir Ihre
            personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Sie um
            eine Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung von Cookies
            einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte
            Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
            Grundlage unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen
            Betrieb unseres Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der
            Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu
            erfüllen.
          </p>

          <p className="mb-4">
            <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):</strong> Abhängig
            davon, ob die Verarbeitung auf Grundlage einer Einwilligung oder gesetzlichen Erlaubnis
            erfolgt, haben Sie jederzeit die Möglichkeit, eine erteilte Einwilligung zu widerrufen
            oder der Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen
            (zusammenfassend als "Opt-Out" bezeichnet). Sie können Ihren Widerspruch zunächst
            mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie die Nutzung von
            Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres
            Onlineangebotes eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von
            Cookies zu Zwecken des Onlinemarketings kann auch mittels einer Vielzahl von Diensten,
            vor allem im Fall des Trackings, über die Webseiten http://optout.aboutads.info und
            http://www.youronlinechoices.com/ erklärt werden. Daneben können Sie weitere
            Widerspruchshinweise im Rahmen der Angaben zu den eingesetzten Dienstleistern und
            Cookies erhalten.
          </p>
          <p className="mb-4">
            <strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:</strong> Bevor
            wir Daten im Rahmen der Nutzung von Cookies verarbeiten oder verarbeiten lassen, bitten
            wir die Nutzer um eine jederzeit widerrufbare Einwilligung. Bevor die Einwilligung nicht
            ausgesprochen wurde, werden allenfalls Cookies eingesetzt, die für den Betrieb unseres
            Onlineangebotes erforderlich sind. Deren Einsatz erfolgt auf der Grundlage unseres
            Interesses und des Interesses der Nutzer an der erwarteten Funktionsfähigkeit unseres
            Onlineangebotes.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <Title>Kommerzielle und geschäftliche Leistungen</Title>
          <p className="mb-4">
            Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und
            Interessenten (zusammenfassend bezeichnet als "Vertragspartner") im Rahmen von
            vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen
            und im Rahmen der Kommunikation mit den Vertragspartnern (oder vorvertraglich), z.B., um
            Anfragen zu beantworten.
          </p>
          <p className="mb-4">
            Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen Pflichten, zur Sicherung
            unserer Rechte und zu Zwecken der mit diesen Angaben einhergehenden Verwaltungsaufgaben
            sowie der unternehmerischen Organisation. Die Daten der Vertragspartner geben wir im
            Rahmen des geltenden Rechts nur insoweit an Dritte weiter, als dies zu den vorgenannten
            Zwecken oder zur Erfüllung gesetzlicher Pflichten erforderlich ist oder mit Einwilligung
            der Vertragspartner erfolgt (z.B. an beteiligte Telekommunikations-, Transport- und
            sonstige Hilfsdienste sowie Subunternehmer, Banken, Steuer- und Rechtsberater,
            Zahlungsdienstleister oder Steuerbehörden). Über weitere Verarbeitungsformen, z.B. zu
            Zwecken des Marketings, werden die Vertragspartner im Rahmen dieser Datenschutzerklärung
            informiert.
          </p>
          <p className="mb-4">
            Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den
            Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in Onlineformularen, durch
            besondere Kennzeichnung (z.B. Farben) bzw. Symbole (z.B. Sternchen o.ä.), oder
            persönlich mit.
          </p>
          <p className="mb-4">
            Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer
            Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die Daten in
            einem Kundenkonto gespeichert werden, z.B., solange sie aus gesetzlichen Gründen der
            Archivierung aufbewahrt werden müssen (z.B. für Steuerzwecke im Regelfall 10 Jahre).
            Daten, die uns im Rahmen eines Auftrags durch den Vertragspartner offengelegt wurden,
            löschen wir entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende des
            Auftrags.
          </p>
          <p className="mb-4">
            Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen,
            gelten im Verhältnis zwischen den Nutzern und den Anbietern die Geschäftsbedingungen und
            Datenschutzhinweise der jeweiligen Drittanbieter oder Plattformen.
          </p>
          <p className="mb-4">
            <strong>Kundenkonto:</strong> Vertragspartner können innerhalb unseres Onlineangebotes
            ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz "Kundenkonto"). Falls die
            Registrierung eines Kundenkontos erforderlich ist, werden Vertragspartner hierauf ebenso
            hingewiesen wie auf die für die Registrierung erforderlichen Angaben. Die Kundenkonten
            sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden. Im Rahmen der
            Registrierung sowie anschließender Anmeldungen und Nutzungen des Kundenkontos speichern
            wir die IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die Registrierung
            nachweisen und etwaigem Missbrauch des Kundenkontos vorbeugen zu können.
          </p>
          <p className="mb-4">
            Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das Kundenkonto betreffenden
            Daten gelöscht, vorbehaltlich, deren Aufbewahrung ist aus gesetzlichen Gründen
            erforderlich. Es obliegt den Kunden, ihre Daten bei erfolgter Kündigung des Kundenkontos
            zu sichern.
          </p>
          <p className="mb-4">
            <strong>Wirtschaftliche Analysen und Marktforschung:</strong> Aus
            betriebswirtschaftlichen Gründen und um Markttendenzen, Wünsche der Vertragspartner und
            Nutzer erkennen zu können, analysieren wir die uns vorliegenden Daten zu
            Geschäftsvorgängen, Verträgen, Anfragen, etc., wobei in die Gruppe der betroffenen
            Personen Vertragspartner, Interessenten, Kunden, Besucher und Nutzer unseres
            Onlineangebotes fallen können.
          </p>
          <p className="mb-4"></p>
          <p className="mb-4">
            Die Analysen erfolgen zum Zweck betriebswirtschaftlicher Auswertungen, des Marketings
            und der Marktforschung (z.B. zur Bestimmung von Kundengruppen mit unterschiedlichen
            Eigenschaften). Dabei können wir, sofern vorhanden, die Profile von registrierten
            Nutzern samt ihrer Angaben, z.B. zu in Anspruch genommenen Leistungen, berücksichtigen.
            Die Analysen dienen alleine uns und werden nicht extern offenbart, sofern es sich nicht
            um anonyme Analysen mit zusammengefassten, also anonymisierten Werten handelt. Ferner
            nehmen wir Rücksicht auf die Privatsphäre der Nutzer und verarbeiten die Daten zu den
            Analysezwecken möglichst pseudonym und, sofern machbar, anonym (z.B. als
            zusammengefasste Daten).
          </p>
          <p className="mb-4">
            <strong>Shop und E-Commerce:</strong> Wir verarbeiten die Daten unserer Kunden, um ihnen
            die Auswahl, den Erwerb, bzw. die Bestellung der gewählten Produkte, Waren sowie
            verbundener Leistungen, als auch deren Bezahlung und Zustellung, bzw. Ausführung zu
            ermöglichen.
          </p>
          <p className="mb-4">
            Die erforderlichen Angaben sind als solche im Rahmen des Bestell- bzw. vergleichbaren
            Erwerbsvorgangs gekennzeichnet und umfassen die zur Auslieferung, bzw.
            Zurverfügungstellung und Abrechnung benötigten Angaben sowie Kontaktinformationen, um
            etwaige Rücksprache halten zu können.
          </p>
          <p className="mb-4">
            <strong>Mobile Applikation:</strong> Wir verarbeiten die Daten der Nutzer unserer Apps,
            angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich als "Nutzer" bezeichnet),
            um ihnen gegenüber unsere vertraglichen Leistungen erbringen zu können sowie auf
            Grundlage berechtigter Interessen, um die Sicherheit unserer App gewährleisten und sie
            weiterzuentwickeln zu können. Die erforderlichen Angaben sind als solche im Rahmen des
            Nutzungs-, Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
            können die zur Leistungserbringung und zur etwaigen Abrechnung benötigten Angaben sowie
            Kontaktinformationen, um etwaige Rücksprachen halten zu können, umfassen. Soweit unsere
            Apps von Plattformen anderer Anbieter (z.B. App-Store von Apple oder GooglePlay) bezogen
            werden, gelten im Verhältnis zwischen den Nutzern und den Anbietern die
            Geschäftsbedingungen und Datenschutzhinweise der jeweiligen Plattformen.
          </p>
          <p className="mb-4">
            <strong>Projekt- und Entwicklungsleistungen:</strong> Wir verarbeiten die Daten unserer
            Kunden sowie Auftraggeber (nachfolgend einheitlich als "Kunden" bezeichnet), um ihnen
            die Auswahl, den Erwerb bzw. die Beauftragung der gewählten Leistungen oder Werke sowie
            verbundener Tätigkeiten als auch deren Bezahlung und Zurverfügungstellung bzw.
            Ausführung oder Erbringung zu ermöglichen.
          </p>
          <p className="mb-4">
            Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw.
            vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung
            und Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen
            halten zu können. Soweit wir Zugang zu Informationen der Endkunden, Mitarbeitern oder
            anderer Personen erhalten, verarbeiten wir diese im Einklang mit den gesetzlichen und
            vertraglichen Vorgaben.
          </p>
          <p className="mb-4">
            <strong>Publikationstätigkeit:</strong> Wir verarbeiten die Daten von unseren
            Kontaktpartnern, Interviewpartnern sowie sonstigen Personen, die Gegenstand unserer
            publizistischen, redaktionellen und journalistischen sowie verwandter Tätigkeiten sind.
            In diesem Zusammenhang verweisen wir auf die Geltung von Schutzvorschriften der
            Meinungs- und Pressefreiheit gem. Art. 85 DSGVO in Verbindung mit den jeweiligen
            nationalen Gesetzen. Die Verarbeitung dient der Erfüllung unserer Auftragstätigkeiten
            und findet im Übrigen insbesondere auf Grundlage des Interesses der Allgemeinheit an
            Informations- und Medienangeboten statt.
          </p>
          <p className="mb-4">
            <strong>Angebot von Software- und Plattformleistungen:</strong> Wir verarbeiten die
            Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich als
            "Nutzer" bezeichnet), um ihnen gegenüber unsere vertraglichen Leistungen erbringen zu
            können sowie auf Grundlage berechtigter Interessen, um die Sicherheit unseres Angebotes
            gewährleisten und es weiterentwickeln zu können. Die erforderlichen Angaben sind als
            solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses
            gekennzeichnet und umfassen die zur Leistungserbringung und Abrechnung benötigten
            Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li></li>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
              Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Kontaktdaten
              (z.B. E-Mail, Telefonnummern), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
              Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta- /Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Interessenten, Geschäfts- und Vertragspartner,
              Kunden.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service,
              Kontaktanfragen und Kommunikation, Büro- und Organisationsverfahren, Verwaltung und
              Beantwortung von Anfragen, Sicherheitsmaßnahmen, Besuchsaktionsauswertung,
              Interessenbasiertes und verhaltensbezogenes Marketing, Profiling (Erstellen von
              Nutzerprofilen).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit.
              c. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <Title>Zahlungsdienstleister</Title>
          <p className="mb-4">
            Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund gesetzlicher Pflichten
            oder sonst auf Grundlage unserer berechtigten Interessen bieten wir den betroffenen
            Personen effiziente und sichere Zahlungsmöglichkeiten an und setzen hierzu neben Banken
            und Kreditinstituten weitere Zahlungsdienstleister ein (zusammenfassend
            "Zahlungsdienstleister").
          </p>
          <p className="mb-4">
            Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie
            z.B. der Name und die Adresse, Bankdaten, wie z.B. Kontonummern oder
            Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie die Vertrags-, Summen- und
            empfängerbezogenen Angaben. Die Angaben sind erforderlich, um die Transaktionen
            durchzuführen. Die eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister
            verarbeitet und bei diesen gespeichert. D.h., wir erhalten keine konto- oder
            kreditkartenbezogenen Informationen, sondern lediglich Informationen mit Bestätigung
            oder Negativbeauskunftung der Zahlung. Unter Umständen werden die Daten seitens der
            Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung
            bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und die
            Datenschutzhinweise der Zahlungsdienstleister.
          </p>
          <p className="mb-4">
            Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise
            der jeweiligen Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten bzw.
            Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese ebenfalls zwecks
            weiterer Informationen und Geltendmachung von Widerrufs-, Auskunfts- und anderen
            Betroffenenrechten.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
              Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Vertragsdaten
              (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kunden, Interessenten.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO).
            </li>
          </ul>

          <p className="mb-4 font-bold">Eingesetzte Dienste und Diensteanbieter:</p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>PayPal:</strong> Zahlungsdienstleistungen und -Lösungen (z.B. PayPal, PayPal
              Plus, Braintree); Dienstanbieter: PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24
              Boulevard Royal, L-2449 Luxembourg; Website: https://www.paypal.com/de;
              Datenschutzerklärung: https://www.paypal.com/de/webapps/mpp/ua/privacy-full.
            </li>
            <li>
              <strong>Stripe:</strong> Zahlungsdienstleistungen; Dienstanbieter: Stripe, Inc., 510
              Townsend Street, San Francisco, CA 94103, USA; Website: https://stripe.com/de;
              Datenschutzerklärung: https://stripe.com/de/privacy.
            </li>
          </ul>
          <Title>Registrierung und Anmeldung</Title>
          <p className="mb-4">
            Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden den Nutzern
            die erforderlichen Pflichtangaben mitgeteilt und zu Zwecken der Bereitstellung des
            Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung verarbeitet. Zu den
            verarbeiteten Daten gehören insbesondere die Login-Informationen (Name, Passwort sowie
            eine E-Mail-Adresse). Die im Rahmen der Registrierung eingegebenen Daten werden für die
            Zwecke der Nutzung des Nutzerkontos und dessen Zwecks verwendet.
          </p>
          <p className="mb-4">
            Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z.B.
            technische Änderungen, per E-Mail informiert werden. Wenn Nutzer ihr Nutzerkonto
            gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto, vorbehaltlich einer
            gesetzlichen Aufbewahrungspflicht, gelöscht. Es obliegt den Nutzern, ihre Daten bei
            erfolgter Kündigung vor dem Vertragsende zu sichern. Wir sind berechtigt, sämtliche
            während der Vertragsdauer gespeicherte Daten des Nutzers unwiederbringlich zu löschen.
          </p>
          <p className="mb-4">
            Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der
            Nutzung des Nutzerkontos speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen
            Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen
            als auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger unbefugter
            Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei
            denn, sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht hierzu
            besteht eine gesetzliche Verpflichtung.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-
              Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos), Meta-
              /Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              Zwecke der Verarbeitung: Vertragliche Leistungen und Service, Sicherheitsmaßnahmen,
              Verwaltung und Beantwortung von Anfragen.
            </li>
            <li>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO), Vertragserfüllung
              und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte
              Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <Title>Blogs und Publikationsmedien</Title>
          <p className="mb-4">
            Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation und Publikation
            (nachfolgend "Publikationsmedium"). Die Daten der Leser werden für die Zwecke des
            Publikationsmediums nur insoweit verarbeitet, als es für dessen Darstellung und die
            Kommunikation zwischen Autoren und Lesern oder aus Gründen der Sicherheit erforderlich
            ist. Im Übrigen verweisen wir auf die Informationen zur Verarbeitung der Besucher
            unseres Publikationsmediums im Rahmen dieser Datenschutzhinweise.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E- Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
              Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta- /Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service,
              Feedback (z.B. Sammeln von Feedback via Online-Formular).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO).
            </li>
          </ul>
          <Title>Kontaktaufnahme</Title>
          <p className="mb-4">
            Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via
            soziale Medien) werden die Angaben der anfragenden Personen verarbeitet, soweit dies zur
            Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.
          </p>
          <p className="mb-4">
            Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder vorvertraglichen
            Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur Beantwortung
            von (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der berechtigten Interessen
            an der Beantwortung der Anfragen.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E- Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
              Fotografien, Videos).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO).
            </li>
          </ul>
          <Title>Umfragen und Befragungen</Title>
          <p className="mb-4">
            Die von uns durchgeführten Umfragen und Befragungen (nachfolgend "Befragungen") werden
            anonym ausgewertet. Eine Verarbeitung personenbezogener Daten erfolgt nur insoweit, als
            dies zu Bereitstellung und technischen Durchführung der Umfragen erforderlich ist (z.B.
            Verarbeitung der IP-Adresse, um die Umfrage im Browser des Nutzers darzustellen oder
            mithilfe eines temporären Cookies (Session-Cookie) eine Wiederaufnahme der Umfrage zu
            ermöglichen) oder Nutzer eingewilligt haben.
          </p>
          <p className="mb-4">
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Teilnehmer um eine
            Einwilligung in die Verarbeitung iherer Daten bitten, ist diese Rechtsgrundlage der
            Verarbeitung, ansonsten erfolgt die Verarbeitung der Daten der Teilnehmer auf Grundlage
            unserer berechtigten Interessen an der Durchführung einer objektiven Umfrage.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP- Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation,
              Direktmarketing (z.B. per E-Mail oder postalisch).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <Title>Bereitstellung des Onlineangebotes und Webhosting</Title>
          <p className="mb-4">
            Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die
            Leistungen von einem oder mehreren Webhosting-Anbietern in Anspruch, von deren Servern
            (bzw. von ihnen verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu diesen
            Zwecken können wir Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
            Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und technische
            Wartungsleistungen in Anspruch nehmen.
          </p>
          <p className="mb-4">
            Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle
            die Nutzer unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der
            Nutzung und der Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die
            notwendig ist, um die Inhalte von Onlineangeboten an Browser ausliefern zu können, und
            alle innerhalb unseres Onlineangebotes oder von Webseiten getätigten Eingaben.
          </p>
          <p className="mb-4">
            <strong>E-Mail-Versand und -Hosting:</strong> Die von uns in Anspruch genommenen
            Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang sowie die Speicherung
            von E-Mails. Zu diesen Zwecken werden die Adressen der Empfänger sowie Absender als auch
            weitere Informationen betreffend den E-Mailversand (z.B. die beteiligten Provider) sowie
            die Inhalte der jeweiligen E- Mails verarbeitet. Die vorgenannten Daten können ferner zu
            Zwecken der Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass
            E-Mails im Internet grundsätzlich nicht verschlüsselt versendet werden. Im Regelfall
            werden E-Mails zwar auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes
            Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den Servern, von denen
            sie abgesendet und empfangen werden. Wir können daher für den Übertragungsweg der
            E-Mails zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung
            übernehmen.
          </p>
          <p className="mb-4">
            <strong>Erhebung von Zugriffsdaten und Logfiles:</strong> Wir selbst (bzw. unser
            Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte
            Serverlogfiles). Zu den Serverlogfiles können die Adresse und Name der abgerufenen
            Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung
            über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers,
            Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende
            Provider gehören.
          </p>
          <p className="mb-4">
            Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B.,
            um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen
            Angriffen, sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server und
            ihre Stabilität sicherzustellen.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Texteingaben,
              Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta- /Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Content Delivery Network (CDN).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO).
            </li>
          </ul>
          <p className="mb-4">Eingesetzte Dienste und Diensteanbieter:</p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>DigitalOcean:</strong> Content-Delivery-Network (CDN); Dienstanbieter:
              DigitalOcean, LLC, 101 Avenue of the Americas, New York, New York 10013, USA; Website:
              https://www.digitalocean.com; Datenschutzerklärung:
              https://www.digitalocean.com/legal/privacy-policy; Privacy Shield (Gewährleistung
              Datenschutzniveau bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt0000000TQNgAAO&status=Active.
            </li>
          </ul>
          <Title>Bewerbungsverfahren</Title>
          <p className="mb-4">
            Das Bewerbungsverfahren setzt voraus, dass Bewerber uns die für deren Beurteilung und
            Auswahl erforderlichen Daten mitteilen. Welche Informationen erforderlich sind, ergibt
            sich aus der Stellenbeschreibung oder im Fall von Onlineformularen aus den dortigen
            Angaben.
          </p>
          <p className="mb-4">
            Grundsätzlich gehören zu den erforderlichen Angaben, die Informationen zur Person, wie
            der Name, die Adresse, eine Kontaktmöglichkeit sowie die Nachweise über die für eine
            Stelle notwendigen Qualifikationen. Auf Anfragen teilen wir zusätzlich gerne mit, welche
            Angaben benötigt werden.
          </p>
          <p className="mb-4">
            Sofern zur Verfügung gestellt, können uns Bewerber ihre Bewerbungen mittels eines
            Onlineformulars übermitteln. Die Daten werden entsprechend dem Stand der Technik
            verschlüsselt
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bewerberdaten (z.B. Angaben zur Person,
              Post- und Kontaktadressen, die zur Bewerbung gehörenden Unterlagen und die darin
              enthaltenen Informationen, wie z.B. Anschreiben, Lebenslauf, Zeugnisse sowie weitere
              im Hinblick auf eine konkrete Stelle oder freiwillig von Bewerbern mitgeteilte
              Informationen zu deren Person oder Qualifikation).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Bewerber.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Bewerbungsverfahren (Begründung und etwaige
              spätere Durchführung sowie mögliche spätere Beendigung des
              Beschäftigungsverhältnisses.).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Art. 9 Abs. 1 S. 1 lit. b DSGVO
              (Bewerbungsverfahren als vorvertragliches bzw. vertragliches Verhältnis) (Soweit im
              Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten im
              Sinne des Art. 9 Abs. 1 DSGVO (z.B. Gesundheitsdaten, wie Schwerbehinderteneigenschaft
              oder ethnische Herkunft) bei Bewerbern angefragt werden, damit der Verantwortliche
              oder die betroffene Person die ihm bzw. ihr aus dem Arbeitsrecht und dem Recht der
              sozialen Sicherheit und des Sozialschutzes erwachsenden Rechte ausüben und seinen bzw.
              ihren diesbezüglichen Pflichten nachkommen kann, erfolgt deren Verarbeitung nach Art.
              9 Abs. 2 lit. b. DSGVO, im Fall des Schutzes lebenswichtiger Interessen der Bewerber
              oder anderer Personen gem. Art. 9 Abs. 2 lit. c. DSGVO oder für Zwecke der
              Gesundheitsvorsorge oder der Arbeitsmedizin, für die Beurteilung der Arbeitsfähigkeit
              des Beschäftigten, für die medizinische Diagnostik, die Versorgung oder Behandlung im
              Gesundheits- oder Sozialbereich oder für die Verwaltung von Systemen und Diensten im
              Gesundheits- oder Sozialbereich gem. Art. 9 Abs. 2 lit. h. DSGVO. Im Fall einer auf
              freiwilliger Einwilligung beruhenden Mitteilung von besonderen Kategorien von Daten,
              erfolgt deren Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.).
            </li>
          </ul>
          <Title>Cloud-Dienste</Title>
          <p className="mb-4">
            Wir nutzen über das Internet zugängliche und auf den Servern ihrer Anbieter ausgeführte
            Softwaredienste (sogenannte "Cloud-Dienste", auch bezeichnet als "Software as a
            Service") für die folgenden Zwecke: Dokumentenspeicherung und Verwaltung,
            Kalenderverwaltung, E-Mail- Versand, Tabellenkalkulationen und Präsentationen, Austausch
            von Dokumenten, Inhalten und Informationen mit bestimmten Empfängern oder
            Veröffentlichung von Webseiten, Formularen oder sonstigen Inhalten und Informationen
            sowie Chats und Teilnahme an Audio- und Videokonferenzen.
          </p>
          <p className="mb-4">
            In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der
            Anbieter gespeichert werden, soweit diese Bestandteil von Kommunikationsvorgängen mit
            uns sind oder von uns sonst, wie im Rahmen dieser Datenschutzerklärung dargelegt,
            verarbeitet werden. Zu diesen Daten können insbesondere Stammdaten und Kontaktdaten der
            Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und deren Inhalte gehören.
            Die Anbieter der Cloud-Dienste verarbeiten ferner Nutzungsdaten und Metadaten, die von
            ihnen zu Sicherheitszwecken und zur Serviceoptimierung verwendet werden.
          </p>
          <p className="mb-4">
            Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder öffentlich zugängliche
            Webseiten
          </p>
          <p className="mb-4">
            Formulare o.a. Dokumente und Inhalte bereitstellen, können die Anbieter Cookies auf den
            Geräten der Nutzer für Zwecke der Webanalyse oder, um sich Einstellungen der Nutzer
            (z.B. im Fall der Mediensteuerung) zu merken, speichern.
          </p>
          <p className="mb-4">
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir um eine Einwilligung in den
            Einsatz der Cloud- Dienste bitten, ist die Rechtsgrundlage der Verarbeitung die
            Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen
            Leistungen sein, sofern der Einsatz der Cloud- Dienste in diesem Rahmen vereinbart
            wurde. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten
            Interessen (d.h., Interesse an effizienten und sicheren Verwaltungs- und
            Kollaborationsprozessen) verarbeitet
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E- Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
              Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta- /Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kunden, Beschäftigte (z.B. Angestellte,
              Bewerber, ehemalige Mitarbeiter), Interessenten, Kommunikationspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Büro- und Organisationsverfahren.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <p className="mb-4">Eingesetzte Dienste und Diensteanbieter:</p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Google Cloud-Dienste:</strong> Cloud-Speicher-Dienste; Dienstanbieter: Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
              Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
              https://cloud.google.com/; Datenschutzerklärung:
              https://www.google.com/policies/privacy, Sicherheitshinweise:
              https://cloud.google.com/security/privacy; Privacy Shield (Gewährleistung
              Datenschutzniveau bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&status=Aktive;
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung im
              Drittland): https://cloud.google.com/terms/data-processing-terms; Zusätzliche Hinweise
              zum Datenschutz: https://cloud.google.com/terms/data-processing-terms.
            </li>
          </ul>
          <Title>Newsletter und Breitenkommunikation</Title>
          <p className="mb-4">
            Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen
            (nachfolgend "Newsletter“) nur mit der Einwilligung der Empfänger oder einer
            gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte
            konkret umschrieben werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im
            Übrigen enthalten unsere Newsletter Informationen zu unseren Leistungen und uns.
          </p>
          <p className="mb-4">
            Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre
            E-Mail- Adresse angeben. Wir können Sie jedoch bitten, einen Namen, zwecks persönlicher
            Ansprache im Newsletter, oder weitere Angaben, sofern diese für die Zwecke des
            Newsletters erforderlich sind, zu tätigen.
          </p>
          <p className="mb-4">
            <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter erfolgt
            grundsätzlich in einem sogenannte Double-Opt-In-Verfahren. D.h., Sie erhalten nach der
            Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden.
            Diese Bestätigung ist notwendig, damit sich niemand mit fremden E-Mail-Adressen anmelden
            kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess
            entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die
            Speicherung des Anmelde- und des
          </p>
          <p className="mb-4">
            Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer bei
            dem Versanddienstleister gespeicherten Daten protokolliert.
          </p>
          <p className="mb-4">
            <strong>Löschung und Einschränkung der Verarbeitung:</strong> Wir können die
            ausgetragenen E-Mail- Adressen bis zu drei Jahren auf Grundlage unserer berechtigten
            Interessen speichern, bevor wir sie löschen, um eine ehemals gegebene Einwilligung
            nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen
            Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit
            möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird. Im
            Fall von Pflichten zur dauerhaften Beachtung von Widersprüchen behalten wir uns die
            Speicherung der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste (sogenannte
            "Blacklist") vor.
          </p>
          <p className="mb-4">
            Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten
            Interessen zu Zwecken des Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen
            Dienstleister mit dem Versand von E-Mails beauftragen, erfolgt dies auf Grundlage
            unserer berechtigten Interessen an einem effizienten und sicheren Versandsystem.
          </p>
          <p className="mb-4">
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der Newsletter erfolgt auf
            Grundlage einer Einwilligung der Empfänger oder, falls eine Einwilligung nicht
            erforderlich ist, auf Grundlage unserer berechtigten Interessen am Direktmarketing,
            sofern und soweit diese gesetzlich, z.B. im Fall von Bestandskundenwerbung, erlaubt ist.
            Soweit wir einen Dienstleister mit dem Versand von E- Mails beauftragen, geschieht dies
            auf der Grundlage unserer berechtigten Interessen. Das Registrierungsverfahren wird auf
            der Grundlage unserer berechtigten Interessen aufgezeichnet, um nachzuweisen, dass es in
            Übereinstimmung mit dem Gesetz durchgeführt wurde.
          </p>
          <p className="mb-4">
            <strong>Inhalte:</strong> Informationen zu uns, unseren Leistungen, Aktionen und
            Angeboten.
          </p>
          <p className="mb-4">
            <strong>Erfolgsmessung:</strong> Die Newsletter enthalten einen sogenannte "web-beacon“,
            d.h., eine pixelgroße Datei, die beim Öffnen des Newsletters von unserem Server, bzw.,
            sofern wir einen Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im
            Rahmen dieses Abrufs werden zunächst technische Informationen, wie Informationen zum
            Browser und Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt des Abrufs,
            erhoben.
          </p>
          <p className="mb-4">
            Diese Informationen werden zur technischen Verbesserung unseres Newsletters anhand der
            technischen Daten oder der Zielgruppen und ihres Leseverhaltens auf Basis ihrer
            Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind) oder der Zugriffszeiten
            genutzt. Diese Analyse beinhaltet ebenfalls die Feststellung, ob die Newsletter geöffnet
            werden, wann sie geöffnet werden und welche Links geklickt werden. Diese Informationen
            können aus technischen Gründen zwar den einzelnen Newsletterempfängern zugeordnet
            werden. Es ist jedoch weder unser Bestreben noch, sofern eingesetzt, das des
            Versanddienstleisters, einzelne Nutzer zu beobachten. Die Auswertungen dienen uns
            vielmehr dazu, die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte an sie
            anzupassen oder unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu
            versenden.
          </p>
          <p className="mb-4">
            Die Auswertung des Newsletters und die Erfolgsmessung erfolgen, vorbehaltlich einer
            ausdrücklichen Einwilligung der Nutzer, auf Grundlage unserer berechtigten Interessen zu
            Zwecken des Einsatzes eines nutzerfreundlichen sowie sicheren Newslettersystems, welches
            sowohl unseren geschäftlichen Interessen dient, als auch den Erwartungen der Nutzer
            entspricht.
          </p>
          <p className="mb-4">
            Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich, in diesem Fall muss
            das gesamte Newsletterabonnement gekündigt, bzw. muss ihm widersprochen werden.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E- Mail, Telefonnummern), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP- Adressen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse
              an Inhalten, Zugriffszeiten).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner.
            </li>
            <li>Zwecke der Verarbeitung: Direktmarketing (z.B. per E-Mail oder postalisch).</li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
            <li>
              <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können den Empfang unseres
              Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen, bzw. dem weiteren
              Empfang widersprechen. Einen Link zur Kündigung des Newsletters finden Sie entweder am
              Ende eines jeden Newsletters oder können sonst eine der oben angegebenen
              Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
            </li>
          </ul>
          <Title>Webanalyse und Optimierung</Title>
          <p className="mb-4">
            Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der Auswertung der
            Besucherströme unseres Onlineangebotes und kann Verhalten, Interessen oder
            demographische Informationen zu den Besuchern, wie z.B. das Alter oder das Geschlecht,
            als pseudonyme Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B.
            erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen oder Inhalte am
            häufigsten genutzt werden oder zur Wiederverwendung einladen. Ebenso können wir
            nachvollziehen, welche Bereiche der Optimierung bedürfen.
          </p>
          <p className="mb-4">
            Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B. unterschiedliche
            Versionen unseres Onlineangebotes oder seiner Bestandteile zu testen und optimieren.
          </p>
          <p className="mb-4">
            Zu diesen Zwecken können sogenannte Nutzerprofile angelegt und in einer Datei
            (sogenannte "Cookie") gespeichert oder ähnliche Verfahren mit dem gleichen Zweck genutzt
            werden. Zu diesen Angaben können z.B. betrachtete Inhalte, besuchte Webseiten und dort
            genutzte Elemente und technische Angaben, wie der verwendete Browser, das verwendete
            Computersystem sowie Angaben zu Nutzungszeiten gehören. Sofern Nutzer in die Erhebung
            ihrer Standortdaten eingewilligt haben, können je nach Anbieter auch diese verarbeitet
            werden.
          </p>
          <p className="mb-4">
            Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir ein
            IP-Masking- Verfahren (d.h., Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz
            der Nutzer. Generell werden die im Rahmen von Webanalyse, A/B-Testings und Optimierung
            keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern
            Pseudonyme. D.h., wir als auch die Anbieter der eingesetzten Software kennen nicht die
            tatsächliche Identität der Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren
            in deren Profilen gespeicherten Angaben.
          </p>
          <p className="mb-4">
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
            Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der
            Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf
            Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten,
            wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
            Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in
            dieser Datenschutzerklärung hinweisen.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
              Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Tracking (z.B.
              interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
              Besuchsaktionsauswertung, Profiling (Erstellen von Nutzerprofilen).
            </li>
            <li>
              <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <Title>Onlinemarketing</Title>
          <p className="mb-4">
            Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings, worunter
            insbesondere die Vermarktung von Werbeflächen oder Darstellung von werbenden und
            sonstigen Inhalten (zusammenfassend als "Inhalte" bezeichnet) anhand potentieller
            Interessen der Nutzer sowie die Messung ihrer Effektivität fallen kann.
          </p>
          <p className="mb-4">
            Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer Datei
            (sogenannte "Cookie") gespeichert oder ähnliche Verfahren genutzt, mittels derer die für
            die Darstellung der vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert
            werden. Zu diesen Angaben können z.B. betrachtete Inhalte, besuchte Webseiten, genutzte
            Onlinenetzwerke, aber auch Kommunikationspartner und technische Angaben, wie der
            verwendete Browser, das verwendete Computersystem sowie Angaben zu Nutzungszeiten
            gehören. Sofern Nutzer in die Erhebung ihrer Standortdaten eingewilligt haben, können
            auch diese verarbeitet werden.
          </p>
          <p className="mb-4">
            Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir zur
            Verfügung stehende IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der
            IP-Adresse) zum Schutz der Nutzer. Generell werden im Rahmen des
            Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder
            Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der
            Onlinemarketingverfahren kennen nicht die tatsächlich Identität der Nutzer, sondern nur
            die in deren Profilen gespeicherten Angaben.
          </p>
          <p className="mb-4">
            Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels ähnlicher
            Verfahren gespeichert. Diese Cookies können später generell auch auf anderen Webseiten
            die dasselbe Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der
            Darstellung von Inhalten analysiert als auch mit weiteren Daten ergänzt und auf dem
            Server des Onlinemarketingverfahrensanbieters gespeichert werden.
          </p>
          <p className="mb-4">
            Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der Fall, wenn
            die Nutzer z.B. Mitglieder eines sozialen Netzwerks sind, dessen
            Onlinemarketingverfahren wir einsetzen und das Netzwerk die Profile der Nutzer im den
            vorgenannten Angaben verbindet. Wir bitten darum, zu beachten, dass Nutzer mit den
            Anbietern zusätzliche Abreden, z.B. durch Einwilligung im Rahmen der Registrierung,
            treffen können.
          </p>
          <p className="mb-4">
            Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen über den Erfolg
            unserer Werbeanzeigen. Jedoch können wir im Rahmen sogenannter Konversionsmessungen
            prüfen, welche unserer Onlinemarketingverfahren zu einer sogenannten Konversion geführt
            haben, d.h. z.B., zu einem Vertragsschluss mit uns. Die Konversionsmessung wird alleine
            zur Analyse des Erfolgs unserer Marketingmaßnahmen verwendet.
          </p>
          <p className="mb-4">
            Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass verwendete Cookies
            für einene Zeitraum von zwei Jahren gespeichert werden.
          </p>
          <p className="mb-4">
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
            Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der
            Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf
            Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten,
            wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
            Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in
            dieser Datenschutzerklärung hinweisen.
          </p>
          <p className="mb-4">
            <strong>Facebook-Pixel:</strong> Mit Hilfe des Facebook-Pixels ist es Facebook zum einen
            möglich, die Besucher unseres Onlineangebotes als Zielgruppe für die Darstellung von
            Anzeigen (sogenannte "Facebook- Ads") zu bestimmen. Dementsprechend setzen wir das
            Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Nutzern bei
            Facebook und innerhalb der Dienste der mit Facebook kooperierenden Partner (so genanntes
            "Audience Network" https://www.facebook.com/audiencenetwork/ ) anzuzeigen, die auch ein
            Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B.
            Interesse an bestimmten Themen oder Produkten, die anhand der besuchten Webseiten
            ersichtlich werden) aufweisen, die wir an Facebook übermitteln (sogenannte "Custom
            Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere
            Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend
            wirken. Mit Hilfe des Facebook-Pixels können wir ferner die Wirksamkeit der
            Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, indem
            wir sehen, ob Nutzer nach dem Klick auf eine Facebook-Werbeanzeige auf unsere Webseite
            weitergeleitet wurden (sogenannte "Konversionsmessung“).
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen), Standortdaten (Daten, die den Standort des
              Endgeräts eines Endnutzers angeben).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten), Interessenten.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Tracking (z.B.
              interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Remarketing,
              Besuchsaktionsauswertung, Interessenbasiertes und verhaltensbezogenes Marketing,
              Profiling (Erstellen von Nutzerprofilen), Konversionsmessung (Messung der Effektivität
              von Marketingmaßnahmen), Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
              wiederkehrender Besucher), Zielgruppenbildung (Bestimmung von für Marketingzwecke
              relevanten Zielgruppen oder sonstige Ausgabe von Inhalten), Cross-Device Tracking
              (geräteübergreifende Verarbeitung von Nutzerdaten für Marketingzwecke).
            </li>
            <li>
              <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
            <li>
              <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Wir verweisen auf die
              Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern angegebenen
              Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine explizite Opt-Out-Möglichkeit
              angegeben wurde, besteht zum einen die Möglichkeit, dass Sie Cookies in den
              Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch Funktionen unseres
              Onlineangebotes eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden
              Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete gerichtet angeboten
              werden: a) Europa: https://www.youronlinechoices.eu. b) Kanada:
              https://www.youradchoices.ca/choices. c) USA: https://www.aboutads.info/choices. d)
              Gebietsübergreifend: http://optout.aboutads.info. Eingesetzte Dienste und
              Diensteanbieter:
            </li>
            <li>
              <strong>Google Tag Manager:</strong> Google Tag Manager ist eine Lösung, mit der wir
              sog. Website-Tags über eine Oberfläche verwalten können (und so z.B. Google Analytics
              sowie andere Google- Marketing-Dienste in unser Onlineangebot einbinden). Der Tag
              Manager selbst (welches die Tags implementiert) verarbeitet keine personenbezogenen
              Daten der Nutzer. Im Hinblick auf die Verarbeitung der personenbezogenen Daten der
              Nutzer wird auf die folgenden Angaben zu den Google-Diensten verwiesen.
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
              USA; Website: https://marketingplatform.google.com; Datenschutzerklärung:
              https://policies.google.com/privacy; Privacy Shield (Gewährleistung Datenschutzniveau
              bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active.
            </li>
            <li>
              <strong>Google Analytics:</strong> Onlinemarketing und Webanalyse; Dienstanbieter:
              Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
              USA; Website: https://marketingplatform.google.com/intl/de/about/analytics/;
              Datenschutzerklärung: https://policies.google.com/privacy; Privacy Shield
              (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active;
              Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
              http://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für die Darstellung von
              Werbeeinblendungen: https://adssettings.google.com/authenticated.
            </li>
            <li>
              <strong>Google Ads und Konversionsmessung:</strong> Wir nutzen das
              Onlinemarketingverfahren "Google Ads", um Anzeigen im Google-Werbe-Netzwerk zu
              platzieren (z.B., in Suchergebnissen, in Videos, auf Webseiten, etc.), damit sie
              Nutzern angezeigt werden, die ein mutmaßliches Interesse an den Anzeigen haben. Ferner
              messen wir die Konversion der Anzeigen. Wir erfahren jedoch nur die anonyme
              Gesamtanzahl der Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit einem
              sog "Conversion-Tracking-Tag" versehenen Seite weitergeleitet wurden. Wir selbst
              erhalten jedoch keine Informationen, mit denen sich Nutzer identifizieren lassen.
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Ireland, parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
              94043, USA; Website: https://marketingplatform.google.com; Datenschutzerklärung:
              https://policies.google.com/privacy; Privacy Shield (Gewährleistung Datenschutzniveau
              bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active.
            </li>
            <li>
              <strong>Google Adsense mit nicht-personalisierten Anzeigen:</strong> Wir nutzen den
              Dienst Google Adsense mit nicht-personalisierten Anzeigen, mit dessen Hilfe innerhalb
              unseres Onlineangebotes Anzeigen eingeblendet werden und wir für deren Einblendung
              oder sonstige Nutzung eine Entlohnung erhalten. ; Dienstanbieter: Google Ireland
              Limited, Gordon House, Barrow Street, Dublin 4, Ireland, parent company: Google LLC,
              1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
              https://marketingplatform.google.com; Datenschutzerklärung:
              https://policies.google.com/privacy; Privacy Shield (Gewährleistung Datenschutzniveau
              bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active.
            </li>
            <li>
              <strong>Facebook-Pixel:</strong> Facebook-Pixel; Dienstanbieter:
              https://www.facebook.com, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
              Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA
              94025, USA; Website: https://www.facebook.com; Datenschutzerklärung:
              https://www.facebook.com/about/privacy; Privacy Shield (Gewährleistung
              Datenschutzniveau bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active;
              Widerspruchsmöglichkeit (Opt-Out): https://www.facebook.com/settings?tab=ads.
            </li>
          </ul>
          <Title>Präsenzen in sozialen Netzwerken</Title>
          <p className="mb-4">
            Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke, um mit den dort aktiven
            Nutzern zu kommunizieren oder um dort Informationen über uns anzubieten.
          </p>
          <p className="mb-4">
            Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen
            Union verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben,
            weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert werden könnte. Im Hinblick
            auf US-Anbieter, die unter dem Privacy-Shield zertifiziert sind oder vergleichbare
            Garantien eines sicheren Datenschutzniveaus bieten, weisen wir darauf hin, dass sie sich
            damit verpflichten, die Datenschutzstandards der EU einzuhalten.
          </p>
          <p className="mb-4">
            Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für
            Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des
            Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer Nutzungsprofile
            erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B.
            Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den
            Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den
            Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der
            Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig
            der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer
            Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).
          </p>
          <p className="mb-4">
            Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
            Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die Datenschutzerklärungen und
            Angaben der Betreiber der jeweiligen Netzwerke.
          </p>
          <p className="mb-4">
            Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen
            wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden
            können. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können
            direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe
            benötigen, dann können Sie sich an uns wenden.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E- Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
              Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta- /Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Tracking
              (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Remarketing,
              Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO). Eingesetzte Dienste und Diensteanbieter:
            </li>
            <li>
              <strong>Instagram:</strong> Soziales Netzwerk; Dienstanbieter: Instagram Inc., 1601
              Willow Road, Menlo Park, CA, 94025, USA; Website: https://www.instagram.com;
              Datenschutzerklärung: http://instagram.com/about/legal/privacy.
            </li>
            <li>
              <strong>Facebook:</strong> Soziales Netzwerk; Dienstanbieter: Facebook Ireland Ltd., 4
              Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, Mutterunternehmen:
              Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website: https://www.facebook.com;
              Datenschutzerklärung: https://www.facebook.com/about/privacy; Privacy Shield
              (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active;
              Widerspruchsmöglichkeit (Opt-Out): Einstellungen für Werbeanzeigen:
              https://www.facebook.com/settings?tab=ads; Zusätzliche Hinweise zum Datenschutz:
              Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten auf Facebook-
              Seiten: https://www.facebook.com/legal/terms/page_controller_addendum,
              Datenschutzhinweise für Facebook-Seiten:
              https://www.facebook.com/legal/terms/information_about_page_insights_data.
            </li>
            <li>
              <strong>Twitter:</strong> Soziales Netzwerk; Dienstanbieter: Twitter Inc., 1355 Market
              Street, Suite 900, San Francisco, CA 94103, USA; Datenschutzerklärung:
              https://twitter.com/de/privacy, (Einstellungen) https://twitter.com/personalization;
              Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den
              USA): https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active.
            </li>
          </ul>
          <Title>Plugins und eingebettete Funktionen sowie Inhalte</Title>
          <p className="mb-4">
            Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den
            Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als "Drittanbieter”) bezogen
            werden. Dabei kann es sich zum Beispiel um Grafiken, Videos oder
            Social-Media-Schaltflächen sowie Beiträge handeln (nachfolgend einheitlich bezeichnet
            als "Inhalte”).
          </p>
          <p className="mb-4">
            Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse
            der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser
            senden könnten. Die IP- Adresse ist damit für die Darstellung dieser Inhalte oder
            Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren
            jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
            Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web
            Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
            "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser
            Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf
            dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum
            Browser und zum Betriebssystem, zu verweisenden Webseiten, zur Besuchszeit sowie weitere
            Angaben zur Nutzung unseres Onlineangebotes enthalten als auch mit solchen Informationen
            aus anderen Quellen verbunden werden.
          </p>
          <p className="mb-4">
            Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren Einwilligung in den Einsatz
            der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
            Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten
            Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen
            Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
            Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <p className="mb-4">
            Einbindung von Drittsoftware, Skripten oder Frameworks (z. B. jQuery): Wir binden in
            unser Onlineangebot Software ein, die wir von Servern anderer Anbieter abrufen (z.B.
            Funktions- Bibliotheken, die wir zwecks Darstellung oder Nutzerfreundlichkeit unseres
            Onlineangebotes verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse der
            Nutzer und können diese zu Zwecken der Übermittlung der Software an den Browser der
            Nutzer sowie zu Zwecken der Sicherheit, als auch zur Auswertung und Optimierung ihres
            Angebotes verarbeiten.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten), Kommunikationspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
              Nutzerfreundlichkeit, Vertragliche Leistungen und Service, Kontaktanfragen und
              Kommunikation, Direktmarketing (z.B. per E-Mail oder postalisch), Tracking (z.B.
              interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Interessenbasiertes
              und verhaltensbezogenes Marketing, Profiling (Erstellen von Nutzerprofilen).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO), Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO).
            </li>
          </ul>
          <p className="mb-4 font-bold">Eingesetzte Dienste und Diensteanbieter:</p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Google Fonts:</strong> Wir binden die Schriftarten ("Google Fonts") des
              Anbieters Google ein, wobei die Daten der Nutzer allein zu Zwecken der Darstellung der
              Schriftarten im Browser der Nutzer verwendet werden. Die Einbindung erfolgt auf
              Grundlage unserer berechtigten Interessen an einer technisch sicheren, wartungsfreien
              und effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie unter
              Berücksichtigung möglicher lizenzrechtlicher Restriktionen für deren Einbindung.
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
              USA; Website: https://fonts.google.com/; Datenschutzerklärung:
              https://policies.google.com/privacy; Privacy Shield (Gewährleistung Datenschutzniveau
              bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active.
            </li>
            <li>
              <strong>Google Maps:</strong> Wir binden die Landkarten des Dienstes “Google Maps” des
              Anbieters Google ein. Zu den verarbeiteten Daten können insbesondere IP-Adressen und
              Standortdaten der Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im
              Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte vollzogen), erhoben werden.
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
              USA; Website: https://maps.google.de; Datenschutzerklärung:
              https://policies.google.com/privacy; Privacy Shield (Gewährleistung Datenschutzniveau
              bei Verarbeitung von Daten in den USA):
              https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active;
              Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
              http://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für die Darstellung von
              Werbeeinblendungen: https://adssettings.google.com/authenticated.
            </li>
            <li>
              <strong>Instagram Plugins und -Schaltflächen:</strong> Instagram Plugins und
              -Schaltflächen - Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und
              Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von
              Instagram teilen können. Dienstanbieter: https://www.instagram.com, Instagram Inc.,
              1601 Willow Road, Menlo Park, CA, 94025, USA; Website: https://www.instagram.com;
              Datenschutzerklärung: http://instagram.com/about/legal/privacy.
            </li>
            <li>
              <strong>MyFonts:</strong> Schriftarten; im Rahmen des Schriftartenabrufs verarbeitete
              Daten die Identifikationsnummer des Webfont-Projektes (anonymisiert), die URL der
              lizenzierten Website, die mit einer Kundennummer verknüpft ist, um den Lizenznehmer
              und die lizenzierten Webfonts zu identifizieren, und die Referrer URL; die
              anonymisierte Webfont- Projekt-Identifikationsnummer wird in verschlüsselten
              Protokolldateien mit solchen Daten für 30 Tage, um die monatliche Anzahl der
              Seitenaufrufe zu ermitteln, gespeichert; Nach einer solchen Extraktion und Speicherung
              der Anzahl der Seitenaufrufe werden die Protokolldateien gelöscht; Dienstanbieter:
              Monotype Imaging Holdings Inc., 600 Unicorn Park Drive, Woburn, Massachusetts 01801,
              USA; Website: https://www.myfonts.co; Datenschutzerklärung:
              https://www.myfonts.com/info/legal/#Privacy.
            </li>
            <li>
              <strong>Twitter Plugins und -Schaltflächen:</strong> Twitter Plugins und
              -Schaltflächen - Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und
              Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von
              Twitter teilen können. Dienstanbieter: Twitter Inc., 1355 Market Street, Suite 900,
              San Francisco, CA 94103, USA; Website: https://twitter.com/de; Datenschutzerklärung:
              https://twitter.com/de/privacy.
            </li>
            <li>
              <strong>Typekit-Schriftarten von Adobe:</strong> Wir binden die Schriftarten
              (\"Typekit fonts\") des Anbieters Adobe ein, wobei die Daten der Nutzer allein zu
              Zwecken der Darstellung der Schriftarten im Browser der Nutzer verwendet werden. Die
              Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch
              sicheren, wartungsfreien und effizienten Nutzung von Schriftarten, deren einheitlicher
              Darstellung sowie unter Berücksichtigung möglicher lizenzrechtlicher Restriktionen für
              deren Einbindung. Dienstanbieter: Adobe Systems Software Ireland Limited, 4-6
              Riverwalk, Citywest Business Campus, Dublin 24, Irland; Website:
              https://www.adobe.com/de; Datenschutzerklärung: https://www.adobe.com/de/privacy.html;
              Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den
              USA): https://www.privacyshield.gov/participant?id=a2zt0000000TNo9AAG&status=Active.
            </li>
          </ul>
          <Title>Planung, Organisation und Hilfswerkzeuge</Title>
          <p className="mb-4">
            Wir setzen Dienstleistungen, Plattformen und Software anderer Anbieter (nachfolgend
            bezeichnet als "Drittanbieter”) zu Zwecken der Organisation, Verwaltung, Planung sowie
            Erbringung unserer Leistungen ein. Bei der Auswahl der Drittanbieter und ihrer
            Leistungen beachten wir die gesetzlichen Vorgaben.
          </p>
          <p className="mb-4">
            In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der
            Drittanbieter gespeichert werden. Hiervon können diverse Daten betroffen sein, die wir
            entsprechend dieser Datenschutzerklärung verarbeiten. Zu diesen Daten können
            insbesondere Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen,
            sonstigen Prozessen und deren Inhalte gehören.
          </p>
          <p className="mb-4">
            Sofern Nutzer im Rahmen der Kommunikation, von Geschäfts- oder anderen Beziehungen mit
            uns auf die Drittanbieter bzw. deren Software oder Plattformen verwiesen werden, können
            die Drittanbieter Nutzungsdaten und Metadaten zu Sicherheitszwecken, zur
            Serviceoptimierung oder zu Marketingzwecken verarbeiten. Wir bitten daher darum, die
            Datenschutzhinweise der jeweiligen Drittanbieter zu beachten.
          </p>
          <p className="mb-4">
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
            Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der
            Verarbeitung von Daten die Einwilligung. Ferner kann deren Einsatz ein Bestandteil
            unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der Drittanbieter in
            diesem Rahmen vereinbart wurde. Ansonsten werden die Daten der Nutzer auf Grundlage
            unserer berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
            empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie
            auch auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung
            hinweisen.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E- Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
              Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta- /Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner, Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Büro- und Organisationsverfahren.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <p className="mb-4 font-bold">Eingesetzte Dienste und Diensteanbieter:</p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Doodle:</strong> Online Terminplanung; Dienstanbieter: Doodle AG, Werdstrasse
              21, Postfach, 8021 Zürich, Schweiz; Website: https://doodle.com/de;
              Datenschutzerklärung: https://doodle.com/de/datenschutzrichtlinie.
            </li>
          </ul>
          <Title>Löschung von Daten</Title>
          <p className="mb-4">
            Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht,
            sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige
            Erlaubnisse entfallen (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist
            oder sie für den Zweck nicht erforderlich sind).
          </p>
          <p className="mb-4">
            Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
            Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die
            Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten,
            die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
            Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum
            Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.
          </p>
          <p className="mb-4">
            Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen der
            einzelnen Datenschutzhinweise dieser Datenschutzerklärung erfolgen.
          </p>
          <Title>Änderung und Aktualisierung der Datenschutzerklärung</Title>
          <p className="mb-4">
            Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu
            informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns
            durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald
            durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
            sonstige individuelle Benachrichtigung erforderlich wird.
          </p>
          <p className="mb-4">
            Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von
            Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die Adressen sich
            über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
          </p>
          <Title>Rechte der betroffenen Personen</Title>
          <p className="mb-4">
            Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere
            aus Art. 15 bis 18 und 21 DSGVO ergeben:
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Widerspruchsrecht:</strong> Sie haben das Recht, aus Gründen, die sich aus
              Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie
              betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
              DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
              gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet,
              um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger
              Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
              Direktwerbung in Verbindung steht.
            </li>
            <li>
              <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte
              Einwilligungen jederzeit zu widerrufen.
            </li>
            <li>
              <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu
              verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
              sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen
              Vorgaben.
            </li>
            <li>
              <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen
              Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die
              Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
            </li>
            <li>
              <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach
              Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten
              unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
              eine Einschränkung der Verarbeitung der Daten zu verlangen.
            </li>
            <li>
              <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende
              Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in
              einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren
              Übermittlung an einen anderen Verantwortlichen zu fordern.
            </li>
            <li>
              <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner nach Maßgabe der
              gesetzlichen Vorgaben das Recht, bei einer Aufsichtsbehörde, insbesondere in dem
              Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts
              des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
              betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
            </li>
          </ul>
          <Title>Begriffsdefinitionen</Title>
          <p className="mb-4">
            In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung
            verwendeten Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor
            allem im Art. 4 DSGVO definiert. Die gesetzlichen Definitionen sind verbindlich. Die
            nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die
            Begriffe sind alphabetisch sortiert.
          </p>
          <ul className="mb-4 list-disc pl-4">
            <li>
              <strong>Besuchsaktionsauswertung:</strong> "Besuchsaktionsauswertung" (englisch
              "Conversion Tracking") bezeichnet ein Verfahren, mit dem die Wirksamkeit von
              Marketingmaßnahmen festgestellt werden kann. Dazu wird im Regelfall ein Cookie auf den
              Geräten der Nutzer innerhalb der Webseiten, auf denen die Marketingmaßnahmen erfolgen,
              gespeichert und dann erneut auf der Zielwebseite abgerufen. Beispielsweise können wir
              so nachvollziehen, ob die von uns auf anderen Webseiten geschalteten Anzeigen
              erfolgreich waren).
            </li>
            <li>
              <strong>Content Delivery Network (CDN):</strong> Ein "Content Delivery Network" (CDN)
              ist ein Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große
              Mediendateien, wie Grafiken oder Programm-Skripte mit Hilfe regional verteilter und
              über das Internet verbundener Server, schneller und sicherer ausgeliefert werden
              können.
            </li>
            <li>
              <strong>Cross-Device Tracking:</strong> Das Cross-Device Tracking ist eine Form des
              Trackings, bei der Verhaltens- und Interessensinformationen der Nutzer
              geräteübergreifend in sogenannten Profilen erfasst werden, indem den Nutzern eine
              Onlinekennung zugeordnet wird. Hierdurch können die Nutzerinformationen unabhängig von
              verwendeten Browsern oder Geräten (z.B. Mobiltelefonen oder Desktopcomputern) im
              Regelfall für Marketingzwecke analysiert werden. Die Onlinekennung ist bei den meisten
              Anbietern nicht mit Klardaten, wie Namen, Postadressen oder E-Mail-Adressen,
              verknüpft.
            </li>
            <li>
              <strong>IP-Masking:</strong> Als "IP-Masking” wird eine Methode bezeichnet, bei der
              das letzte Oktett, d.h., die letzten beiden Zahlen einer IP-Adresse, gelöscht wird,
              damit die IP-Adresse nicht mehr der eindeutigen Identifizierung einer Person dienen
              kann. Daher ist das IP-Masking ein Mittel zur Pseudonymisierung von
              Verarbeitungsverfahren, insbesondere im Onlinemarketing
            </li>
            <li>
              <strong>Interessenbasiertes und verhaltensbezogenes Marketing:</strong> Von
              interessens- und/oder verhaltensbezogenem Marketing spricht man, wenn potentielle
              Interessen von Nutzern an Anzeigen und sonstigen Inhalten möglichst genau vorbestimmt
              werden. Dies geschieht anhand von Angaben zu deren Vorverhalten (z.B. Aufsuchen von
              bestimmten Webseiten und Verweilen auf diesen, Kaufverhaltens oder Interaktion mit
              anderen Nutzern), die in einem sogenannten Profil gespeichert werden. Zu diesen
              Zwecken werden im Regelfall Cookies eingesetzt.
            </li>
            <li>
              <strong>Konversionsmessung:</strong> Die Konversionsmessung ist ein Verfahren, mit dem
              die Wirksamkeit von Marketingmaßnahmen festgestellt werden kann. Dazu wird im
              Regelfall ein Cookie auf den Geräten der Nutzer innerhalb der Webseiten, auf denen die
              Marketingmaßnahmen erfolgen, gespeichert und dann erneut auf der Zielwebseite
              abgerufen. Beispielsweise können wir so nachvollziehen, ob die von uns auf anderen
              Webseiten geschalteten Anzeigen erfolgreich waren.
            </li>
            <li>
              <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle
              Informationen, die sich auf eine identifizierte oder identifizierbare natürliche
              Person (im Folgenden "betroffene Person“) beziehen; als identifizierbar wird eine
              natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
              zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
              Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
              identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen,
              psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
              Person sind.
            </li>
            <li>
              <strong>Profiling:</strong> Als "Profiling“ wird jede Art der automatisierten
              Verarbeitung personenbezogener Daten bezeichnet, die darin besteht, dass diese
              personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich
              auf eine natürliche Person beziehen (je nach Art des Profilings gehören dazu
              Informationen betreffend das Alter, das Geschlecht, Standortdaten und Bewegungsdaten,
              Interaktion mit Webseiten und deren Inhalten, Einkaufsverhalten, soziale Interaktionen
              mit anderen Menschen) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die
              Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf einer
              Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies und
              Web-Beacons eingesetzt.
            </li>
            <li>
              <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web Analytics
              bezeichnet) dient der Auswertung der Besucherströme eines Onlineangebotes und kann das
              Verhalten oder Interessen der Besucher an bestimmten Informationen, wie z.B. Inhalten
              von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können Webseiteninhaber z.B.
              erkennen, zu welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie
              sich interessieren. Dadurch können sie z.B. die Inhalte der Webseite besser an die
              Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der Reichweitenanalyse werden häufig
              pseudonyme Cookies und Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen
              und so genauere Analysen zur Nutzung eines Onlineangebotes zu erhalten.
            </li>
            <li>
              <strong>Remarketing:</strong> Vom "Remarketing“ bzw. "Retargeting“ spricht man, wenn
              z.B. zu Werbezwecken vermerkt wird, für welche Produkte sich ein Nutzer auf einer
              Webseite interessiert hat, um den Nutzer auf anderen Webseiten an diese Produkte, z.B.
              in Werbeanzeigen, zu erinnern.
            </li>
            <li>
              <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das Verhalten von Nutzern
              über mehrere Onlineangebote hinweg nachvollzogen werden kann. Im Regelfall werden im
              Hinblick auf die genutzten Onlineangebote Verhaltens- und Interessensinformationen in
              Cookies oder auf Servern der Anbieter der Trackingtechnologien gespeichert
              (sogenanntes Profiling). Diese Informationen können anschließend z.B. eingesetzt
              werden, um den Nutzern Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen
              entsprechen.
            </li>
            <li>
              <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder
              juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
              mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
              entscheidet, bezeichnet.
            </li>
            <li>
              <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe
              automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
              Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst
              praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern,
              das Übermitteln oder das Löschen.
            </li>
            <li>
              <strong>Zielgruppenbildung:</strong> Von Zielgruppenbildung (bzw. "Custom Audiences“)
              spricht man, wenn Zielgruppen für Werbezwecke, z.B. Einblendung von Werbeanzeigen,
              bestimmt werden. So kann z.B. anhand des Interesses eines Nutzers an bestimmten
              Produkten oder Themen im Internet geschlussfolgert werden, dass dieser Nutzer sich für
              Werbeanzeigen für ähnliche Produkte oder den Onlineshop, in dem er die Produkte
              betrachtet hat, interessiert. Von "Lookalike Audiences“ (bzw. ähnlichen Zielgruppen)
              spricht man wiederum, wenn die als geeignet eingeschätzten Inhalte Nutzern angezeigt
              werden, deren Profile bzw. Interessen mutmaßlich den Nutzern, zu denen die Profile
              gebildet wurden, entsprechen. Zu Zwecken der Bildung von Custom Audiences und
              Lookalike Audiences werden im Regelfall Cookies und Web-Beacons eingesetzt.
            </li>
          </ul>
          <p className="text-sm italic">
            Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke
          </p>
        </Wrapper>
      </Section>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default TOC;
